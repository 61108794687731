<template>
    <div>
        <md-table-card class="list" style="width:100%;">
            <md-card-header>
                <h1 class="md-title center">Variants</h1>
            </md-card-header>
            <md-table>
                <md-table-header>
                    <md-table-row>
                        <md-table-head>Preview</md-table-head>
                        <md-table-head>Name</md-table-head>
                        <md-table-head>Size</md-table-head>
                        <md-table-head>Price</md-table-head>
                        <md-table-head>Stock</md-table-head>
                        <md-table-head>Image</md-table-head>
                    </md-table-row>
                </md-table-header>
                <md-table-body>
                    <md-table-row v-for="(variant,ix) in product.variants" :key="ix">
                        <md-table-cell>
                            <img :src="variant.image | googleimage" style="width:150px" />
                        </md-table-cell>
                        <md-table-cell>
                            <md-input-container>
                                <md-input v-model="variant.name"></md-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-input-container>
                                <md-input v-model="variant.size"></md-input>
                            </md-input-container>
                        </md-table-cell>

                        <md-table-cell>
                            <md-input-container>
                                <md-input v-model="variant.price"></md-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-input-container>
                                <md-input v-if="!product.unlimited" v-model="variant.stock"></md-input>
                                <md-input v-if="product.unlimited" value="unlimited" :disabled="true"></md-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-input-container>
                                <image-input v-model="variant.image" folder="product"></image-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-button type="button" class="md-icon-button" @click="removeVariant(variant)">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table-body>
            </md-table>
            <md-button class="md-primary" @click="addVariant">Add another variant</md-button>
        </md-table-card>

    </div>
</template>

<script>
import ImageInput from "@/components/ImageInput";
export default {
    props: ["product"],
    components: {
        ImageInput
    },
    data() {
        return {
            variants: [],
            uniqvariants: []
        };
    },
    methods: {
        addVariant() {
            if (!this.product.variants) {
                this.$set(this.product, "variants", []);
            }
            this.product.variants.push({ size: null, weight: null, price: 0, stock: 0, image: "" });
        },
        removeVariant(a) {
            this.product.variants = this.product.variants.filter(item => item !== a);
        }
    }
};
</script>
