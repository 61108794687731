<template>
    <div class="package-items-edit">
        <md-table-card style="width:100%;">
            <md-card-header>
                <h1 class="md-title center">Package Items</h1>
            </md-card-header>
            <md-table>
                <md-table-header>
                    <md-table-row>
                        <md-table-head style="width:35%">name</md-table-head>
                        <md-table-head>quantity</md-table-head>
                        <md-table-head>ctrl</md-table-head>
                    </md-table-row>
                </md-table-header>
                <md-table-body>
                    <md-table-row v-for="(item,ix) in items" :key="ix">
                        <md-table-cell>
                            <router-link v-if="!item.isNew" :to="'/'+item.slug+'/'+item._id">
                                <span>{{item.name}} / {{item.vname}}</span>
                            </router-link>
                            <md-input-container v-if="item.isNew">
                                <md-autocomplete v-model="item.disname" :list="variants" print-attribute="disname" :filter-list="nameFilter" @selected="setItem(item,$event)" :debounce="200"></md-autocomplete>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-input-container>
                                <md-input v-model="item.quantity"></md-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-button type="button" class="md-icon-button" @click="removeItem(item)">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table-body>

            </md-table>
            <md-button class="md-primary" @click="addItem">Add another item</md-button>

        </md-table-card>
    </div>
</template>
<script>
export default {
    props: ["items", "rental", "vendor"],
    data() {
        return {
            products: []
        };
    },
    watch: {
        rental() {
            if (!this.rental) {
                //remove rental items
                var items = this.items.filter(i => i.rental);
                items.forEach(item => this.removeItem(item));
            }
        }
    },
    computed: {
        variants() {
            return [].concat(
                ...this.products.filter(p => (this.rental ? true : !p.rental)).map(p =>
                    p.variants.map(v => ({
                        _id: p._id,
                        vid: v._id,
                        name: p.name,
                        vname: v.name,
                        rental: p.rental,
                        rentalDeposit: p.rentalDeposit,
                        price: v.price,
                        disname: p.name + " / " + (v.name || "")
                    }))
                )
            );
        }
    },
    methods: {
        removeItem(item) {
            var index = this.items.indexOf(item);
            this.items.splice(index, 1);
        },
        addItem() {
            this.items.push({ isNew: true });
            console.info(this.items);
        },
        setItem(item, v) {
            var { _id, vid, name, vname, quantity, rental } = v;
            this.$set(item, "_id", _id);
            this.$set(item, "pid", _id);
            this.$set(item, "vid", vid);
            this.$set(item, "quantity", 1);
            this.$set(item, "name", name);
            this.$set(item, "vname", vname);
            this.$set(item, "rental", rental);
            this.$set(item, "disname", name + " / " + (vname || ""));
        },
        nameFilter(list, query) {
            var reg = new RegExp(query, "i");
            var arr = list.filter(item => (reg.test(item.name) || reg.test(item.vname)) && !this.items.find(i => i.vid == item.vid));
            return arr.splice(0, 5);
        },
        async getMyProduct() {
            var result = await this.$store.dispatch("crud/get", {
                api: "products/allByVendor/" + (this.vendor || ""),
                params: {
                    type: "Product"
                }
            });
            this.products = result;
        }
    },
    created() {
        this.getMyProduct();
    }
};
</script>