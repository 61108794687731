<template>
    <div class="product-list">
        <list :addLink="addLink" :apiList="apiList" :editLink="editLink" :fs="fs" :pageMode="true" :showAction="showAction" :title="type + 's'" api="products"></list>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { productFields, packageFields } from "@/config";
import List from "@/components/List";
export default {
    props: {
        type: {
            type: String,
            default: "Product" //package
        }
    },
    components: {
        List
    },
    data() {
        return {
            products: []
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            paymentSetting: "setting/payment",
            hasPermission: "user/hasPermission"
        }),
        productFields() {
            const fs = JSON.parse(JSON.stringify(productFields));
            if (!this.hasRole("manager")) {
                const approvedField = fs.find(field => field.data == "approved");
                approvedField.action = false;
            }
            return fs;
        },
        packageFields() {
            const fs = JSON.parse(JSON.stringify(packageFields));
            if (!this.hasRole("manager")) {
                const approvedField = fs.find(field => field.data == "approved");
                approvedField.action = false;
            }
            return fs;
        },
        fs() {
            return this.type == "Product" ? this.productFields : this.packageFields;
        },
        apiList() {
            return `products?type=${this.type}`;
        },
        editLink() {
            return `/admin/${this.type.toLowerCase()}s`;
        },
        addLink() {
            return this.user.role == "vendor" ? `/admin/${this.type.toLowerCase()}s/new` : "";
        },
        showAction() {
            return !(this.user.role == "staff" && !this.hasPermission("edit_product"));
        }
    },
    methods: {
        checkVendorRequired() {
            let user = this.user;
            // all vendor
            if (user.role == "vendor") {
                if (user.locations.length == 0) {
                    this.$router.push("/account/vendor/location");
                    this.$store.commit("setMessage", "Please set service locations first");
                    return;
                }
            }

            // master vendor
            if (user.role == "vendor" && !user.master_vendor_id) {
                if (this.paymentSetting.value.use_tilled) {
                    if (!user.tilledId) {
                        this.$router.push("/admin/EzTimePay/cards/new");
                        this.$store.commit("setMessage", "Please add your card first");
                        return;
                    }
                    if (!user.tilledConnectId) {
                        this.$router.push("/account/profile");
                        this.$store.commit("setMessage", "Please connect EzTimePay account");
                        return;
                    }
                } else if (this.paymentSetting.value.use_stripe) {
                    if (!user.stripeId) {
                        this.$router.push("/admin/stripe/cards/new");
                        this.$store.commit("setMessage", "Please add your card first");
                        return;
                    }
                    if (!user.stripeConnectId) {
                        this.$router.push("/account/profile");
                        this.$store.commit("setMessage", "Please connect stripe account");
                        return;
                    }
                }
            }
        }
    },
    async created() {
        setTimeout(() => {
            this.checkVendorRequired();
        }, 0);

        await this.$store.dispatch("category/loadCategories");
    }
};
</script>
