<template>
    <div class="product-images">
        <md-table-card class="list" style="width:100%;">
            <md-card-header>
                <h1 class="md-title center">Additional Images</h1>
            </md-card-header>
            <md-table>
                <md-table-header>
                    <md-table-row>
                        <md-table-head>Preview</md-table-head>
                        <md-table-head>Image</md-table-head>
                    </md-table-row>
                </md-table-header>
                <md-table-body>
                    <md-table-row v-for="(image,ix) in product.images" :key="ix">
                        <md-table-cell>
                            <img :src="image | googleimage" style="width:150px" />
                        </md-table-cell>
                        <md-table-cell>
                            <md-input-container>
                                <image-input v-model="product.images[ix]" folder="product"></image-input>
                            </md-input-container>
                        </md-table-cell>
                        <md-table-cell>
                            <md-button type="button" class="md-icon-button" @click="removeImage(image)">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table-body>
            </md-table>
            <md-button class="md-primary" @click="addImage">Add another image</md-button>
        </md-table-card>
    </div>
</template>
<script>
import ImageInput from "@/components/ImageInput";

export default {
    props: ["product"],
    components: {
        ImageInput
    },
    data() {
        return {};
    },
    methods: {
        addImage() {
            if (!this.product.images) {
                this.$set(this.product, "images", []);
            }
            this.product.images.push("");
        },
        removeImage(a) {
            this.product.images = this.product.images.filter(item => item !== a);
        }
    }
};
</script>

