<template>
  <div class="product-features">
    <md-table-card class="list" style="width:100%;">
      <md-card-header>
        <h1 class="md-title center">Adjust Features</h1>
      </md-card-header>
      <md-table>
        <md-table-header>
          <md-table-row>
            <md-table-head>Key</md-table-head>
            <md-table-head>Value</md-table-head>
          </md-table-row>
        </md-table-header>
        <md-table-body>
          <md-table-row v-for="feature in product.features" :key="feature._id">
            <md-table-cell>
              <md-input-container>
                <label>Key</label>
                <md-select v-model="feature.key">
                  <md-option v-for="f in uniqFeatures" :key="f._id" :value="f.key">
                    {{ f.key }}
                  </md-option>
                </md-select>
              </md-input-container>
            </md-table-cell>
            <md-table-cell>
              <md-input-container>
                <label>Value </label>
                <md-select v-model="feature.val">
                  <md-option v-for="f in features.filter(item => item.key === feature.key)" :key="f._id" :value="f.val">
                    {{ f.val }}
                  </md-option>
                </md-select>
              </md-input-container>
            </md-table-cell>
            <md-table-cell>
              <md-button type="button" class="md-icon-button" @click="removeFeature(feature)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table-body>
      </md-table>
      <md-button class="md-primary" @click="addFeature">Add another feature</md-button>
    </md-table-card>
  </div>
</template>

<script>
export default {
  props: ['product', 'features'],
  data() {
    return {
      myFeatures: [],
      uniqFeatures: []
    }
  },
  methods: {
    uniq(item) {
      let output = [];
      let keys = [];
      item.forEach((post) => {
        let key = post['key'];
        if (keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(post);
        }
      });
      return output;
    },
    addFeature() {
      this.product.features.push({ key: null, val: null })
    },
    removeFeature(a) {
      this.product.features = this.product.features.filter((item) => item !== a);
    }
  },
  created() {
    this.myFeatures = this.features
    this.uniqFeatures = this.uniq(this.features)
  },
}
</script>
<style lang="less">
.product-features {
    .list {
        width: 380px;
    }
}
</style>
