<template>
    <div class="product-edit">
        <md-card>
            <form v-on:submit.prevent="save()">
                <md-card-header class="header">
                    <div class="md-title">{{ product._id === "new" ? "Add" : "Edit" }} {{ type | titlecase }} > {{ product.name }}</div>
                    <div style="flex:1"></div>
                    <submit :disabled="errors.any()" :loading="loading" icon="save" text="Save"></submit>
                </md-card-header>
                <md-card-content>
                    <md-switch v-model="product.hot">Hot</md-switch>
                    <md-switch v-model="product.new">New</md-switch>
                    <md-switch v-model="product.sale">Sale</md-switch>
                    <md-switch v-model="product.unlimited">Unlimited</md-switch>
                    <md-switch v-model="product.pickup">Pick Up</md-switch>
                    <md-switch v-model="product.rental">Rental</md-switch>
                    <md-switch v-model="product.active">Active</md-switch>
                    <md-switch :disabled="!hasRole('manager')" v-model="product.approved">{{ product.approved ? "Approved" : "Need Approve" }}</md-switch>
                    <md-input-container>
                        <label for>Visibility</label>
                        <md-select v-model="product.staffOnly">
                            <md-option :value="false">Everyone</md-option>
                            <md-option :value="true">Staff Only</md-option>
                        </md-select>
                    </md-input-container>
                    <md-input-container>
                        <label>Name</label>
                        <md-input autofocus placeholder="Name" v-model="product.name"></md-input>
                    </md-input-container>
                    <md-input-container v-if="type == 'Package' && product.variants && product.variants.length">
                        <label for>Price</label>
                        <md-input placeholder="Price" v-model="product.variants[0].price"></md-input>
                    </md-input-container>
                    <md-input-container v-if="type == 'Package' && product.variants && product.variants.length">
                        <label for>Stock</label>
                        <md-input placeholder="Stock" v-model="product.variants[0].stock"></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for>Category</label>
                        <category-select v-model="product.category"></category-select>
                    </md-input-container>
                    <md-input-container>
                        <label>Maintenance / Grace Period</label>
                        <md-input placeholder="Maintenance / Grace Period" v-model="product.prepareDays"></md-input>
                    </md-input-container>
                    <md-input-container v-if="product.rental">
                        <label>Interval</label>
                        <md-select v-model="product.rentalInterval">
                            <md-option value="hourly">hourly</md-option>
                            <md-option value="daily">daily</md-option>
                        </md-select>
                    </md-input-container>
                    <md-input-container v-if="product.rental">
                        <label>RentalDeposit</label>
                        <md-select v-model="product.rentalDeposit">
                            <md-option :value="0">0%</md-option>
                            <md-option :value="0.25">25%</md-option>
                            <md-option :value="0.5">50%</md-option>
                            <md-option :value="0.75">75%</md-option>
                            <md-option :value="1">100%</md-option>
                        </md-select>
                    </md-input-container>
                    <md-input-container v-if="product.rental">
                        <label v-if="product.rentalInterval == 'daily'">Day Minimum Booking</label>
                        <label v-if="product.rentalInterval == 'hourly'">Hour Minimum Booking</label>
                        <md-input :placeholder="product.rentalInterval == 'daily' ? 'RentalMinDays' : 'RentalMinHours'" v-model="product.rentalMinDays"></md-input> </md-input-container
                    >·
                    <package-items-edit
                        :items="product.variants[0].items"
                        :rental="product.rental"
                        :vendor="product.vendor_id"
                        v-if="type == 'Package' && product.variants && product.variants[0]"
                    ></package-items-edit>
                    <product-variants :product="product" v-if="type == 'Product'"></product-variants>
                    <product-add-on-items-edit :product="product"></product-add-on-items-edit>
                    <product-images :product="product"></product-images>
                    <product-features :features="features" :product="product" class="flex" v-if="type == 'Product'"></product-features>
                    <product-key-features :features="features" :product="product" class="ml10 flex" v-if="type == 'Product'"></product-key-features>
                    <md-input-container>
                        <label>Description</label>
                        <email-editor v-model="product.description"></email-editor>
                    </md-input-container>
                </md-card-content>
                <md-card-actions>
                    <submit :disabled="errors.any()" :loading="loading" icon="save" text="Save"></submit>
                </md-card-actions>
            </form>
        </md-card>
    </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import ProductImages from "@/components/Product/ProductImages";
import ProductVariants from "@/components/Product/ProductVariants";
import ProductAddOnItemsEdit from "@/components/Product/ProductAddOnItemsEdit";
import ProductFeatures from "@/components/Product/ProductFeatures";
import ProductKeyFeatures from "@/components/Product/ProductKeyFeatures";
import PackageItemsEdit from "@/components/Product/PackageItemsEdit";
import EmailEditor from "@/components/EmailEditor";
import CategorySelect from "@/components/Category/CategorySelect";
import Submit from "@/components/Submit";

export default {
    props: {
        id: { default: "new" },
        type: { type: String, default: "Product" }
    },
    components: {
        ProductFeatures,
        ProductKeyFeatures,
        ProductAddOnItemsEdit,
        ProductVariants,
        ProductImages,
        PackageItemsEdit,
        EmailEditor,
        CategorySelect,
        Submit
    },
    data() {
        return {
            product: {
                _id: "new",
                rental: true,
                rentalInterval: "daily",
                rentalDeposit: 0.5,
                type: "Proudct"
            },
            features: [],
            loading: false
        };
    },
    watch: {},
    computed: {
        ...mapGetters({
            hasRole: "user/hasRole"
        })
    },
    methods: {
        async load() {
            if (this.id && this.id != "new") {
                this.product = await this.$store.dispatch("crud/get", {
                    api: `products/${this.id}`
                });
            } else {
                this.product.type = this.type;
                if (this.type == "Package") {
                    this.$set(this.product, "variants", [{ items: [] }]);
                }
            }
            this.features = await this.$store.dispatch("crud/get", {
                api: "features",
                params: {}
            });
        },
        async save() {
            if (!this.product.variants.length) {
                return this.$store.commit("setMessage", "The Variants should not be empty", { root: true });
            }
            if (this.product.type == "Package") {
                this.product.variants[0].items = this.product.variants[0].items.filter(i => i.pid && i.vid);
            }

            if (this.id === "new") {
                delete this.product._id;
                let data = await this.$store.dispatch("crud/post", {
                    api: "products",
                    data: this.product
                });
            } else {
                let data = await this.$store.dispatch("crud/put", {
                    api: "products/" + this.id,
                    data: this.product
                });
            }
            this.$store.commit("setMessage", "save success", { root: true });
            this.$router.push(`/admin/${this.type.toLowerCase()}s`);
        }
    },

    async created() {
        this.product._id = this.id || "new";
        await this.load();
    }
};
</script>
<style lang="less">
.product-edit {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .email-editor {
        margin-top: 30px;
    }
}
</style>
